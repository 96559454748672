.rhap_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1c1c1c;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.rhap_progress-bar {
    height: 5px;
    background-color: #72fa41;
    position: absolute;
    left: 0;
    top: 0;
}

/* .rhap_main-controls::before {
    content: url('../../assets/images/other/Ryan_Zernach_2023_Mobile_App_Developer_React_Native_Remote_STEM_Tech_Network_iOS_Android_Miami_Beach.png');
} */

/* Controls */
.rhap_main-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: -20px;
    margin-bottom: -15px;
}

.rhap_main-controls button {
    background-color: #1c1c1c;
    border: none;
    color: #ffffff;
    font-size: 24px;
    cursor: pointer;
    outline: none;
    transition: all 0.2s ease-in-out;
}

.rhap_main-controls button:hover {
    color: #72fa41;
}

.rhap_repeat-button {
    display: none;
}

.rhap_main-controls button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.rhap_time {
    color: #c1c1c1;
    /* font-size: 14px;
    margin-top: 10px; */
    display: none;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 3.6em;
    line-height: 1.8em;
}

.rhap_volume-controls {
    display: none;
    align-items: center;
    margin-top: 10px;
}

.rhap_volume-controls button {
    background-color: #1c1c1c;
    border: none;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
    outline: none;
    transition: all 0.2s ease-in-out;
}

.rhap_volume-controls button:hover {
    color: #72fa41;
}

.rhap_volume-controls .rhap_volume-container {
    width: 100px;
    height: 5px;
    background-color: #ffffff99;
    position: relative;
    overflow: hidden;
    margin-left: 10px;
}

.rhap_volume-controls .rhap_volume-bar {
    height: 5px;
    background-color: #72fa41;
    position: absolute;
    left: 0;
    top: 0;
}

.rhap_volume-controls .rhap_volume-indicator {
    width: 10px;
    height: 10px;
    background-color: #72fa41;
    border-radius: 50%;
    position: absolute;
    top: -3px;
    left: -5px;
}

.rhap_volume-controls .rhap_volume-indicator::before {
    content: "";
    width: 8px;
    height: 8px;
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 1px;
}